<template>
    <div class="g-section job-container">
        <div class="g-wrap job-top">
        </div>
        <div v-if="list.length>0" class="g-wrap job-bottom" v-loading="loading">
            <div v-for="item in list" class="job-item" :key="item.Id">
                <div class="item-top">
                    <div class="item-left">
                        <div class="mb-10 fs-14 color-base">
                            <span class="mb-10 job-name">{{ item.Name }}</span>
                        </div>
                        <div class="fs-14">
                            <span class="mb-10 job-text">{{ item.Description }}</span>
                        </div>
                    </div>
                </div>
                <div class="item-bottom">
                    <el-button type="text" icon="el-icon-search" size="small" class="color-primary" @click="goDetail(item)">{{item.TeacherEvaluateId?"已评价":"去评价"}}</el-button>
                </div>
            </div>
        </div>
        <div v-else class="g-wrap job-bottom nodata">
            <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
            <span class="mt-18">暂无数据</span>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <div class="dialogBox">
                <el-form ref="form" :model="form" label-width="120px" :rules="rules" :disabled="disabled" v-loading="loading">
                    <el-form-item label="学习态度：" prop="StudyAttitude">
                        <el-rate style="line-height: 2;" v-model="form.StudyAttitude" :max="10"></el-rate>
                    </el-form-item>
                    <el-form-item label="任务完成质量：" prop="QualityOfCompletionTask">
                        <el-rate style="line-height: 2;" v-model="form.QualityOfCompletionTask" :max="10"></el-rate>
                    </el-form-item>
                    <el-form-item label="评价内容：">
                        <el-input type="textarea" v-model="form.Description" placeholder="请输入工作内容" resize="none" rows="5"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="loading" @click="dialogVisible = false" :disabled="disabled">取 消</el-button>
                <el-button :loading="loading" type="primary" @click="handleEvaluate" :disabled="disabled">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { getTeacherProjectClass } from "@/api/internshipLayout";
    import { getInfo, createEntity } from "@/api/teacherEvaluate";

    export default {
        data() {
            return {
                dialogVisible: false,
                isEvaluate: false,
                list: [],
                form: {
                    ProjectClassId: "",
                    StudyAttitude: 0,
                    QualityOfCompletionTask: 0,
                    Description: "",
                },
                rules: {
                    StudyAttitude: [
                        { required: true, message: "请输入项目名称", trigger: "blur" },
                    ],
                    QualityOfCompletionTask: [
                        { required: true, message: "请输入项目名称", trigger: "blur" },
                    ],
                },
                keyword: "",
                loading: false,
                pageIndex: 0,
                pageSize: 5,
                totalCount: 0,
                SchoolId: undefined,
                recruitJobPushSchoolList: [],
                currentRecruitJobId: "",
                currentRecruitJobName: "",
                currentId: "",
                title: '',
                disabled: true
            };
        },
        computed: {
            ...mapGetters(["employee"]),
        },
        mounted() {},
        created() {
            this.getProjectClassList();
        },
        methods: {
            async getProjectClassList() {
                const { Result } = await getTeacherProjectClass();
                if (Result) {
                    this.list = [Result];
                } else {
                    this.list = []
                }
            },
            async handleSubmit() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        try {
                            const Result = await createEntity(this.form);
                            // this. = Result;
                            if (Result.IsSuccess) {
                                this.$message.success("添加成功");
                                this.dialogVisible = false;
                            }
                            this.loading = false;
                        } catch (error) {
                            this.dialogVisible = false;
                            this.loading = false;
                        }
                    } else {
                        this.$message.error("您没有输入必填项");
                        return false;
                    }
                });
            },
            async handleEvaluate(id) {
                this.loading = true;
                if (this.form.StudyAttitude == 0 || this.form.QualityOfCompletionTask == 0) {
                    this.$confirm("请先评分再提交", "提示", {
                        confirmButtonText: "确定",
                        showCancelButton: false,
                        type: "warning",
                    }).then(() => {}).catch(() => {});
                    this.loading = false;
                    return;
                }
                try {
                    const { Result: { Id, ProjectClassId } = {} } = await createEntity(this.form);
                    this.list.forEach(item => {
                        if (item.Id == ProjectClassId) {
                            item.TeacherEvaluateId = Id;
                        }
                    })
                    this.loading = false;
                    this.dialogVisible = false;
                } catch (error) {
                    this.loading = false;
                }
            },
            handleClose(done) {
                this.$confirm("确认关闭？")
                    .then((_) => {
                        done();
                    })
                    .catch((_) => {});
            },
            async goDetail(item) {

                this.form.ProjectClassId = "";
                this.form.StudyAttitude = 0;
                this.form.QualityOfCompletionTask = 0;
                this.form.Description = "";

                this.title = item.Name;
                if (item.TeacherEvaluateId) {
                    this.disabled = true;
                    const { Result } = await getInfo(item.TeacherEvaluateId);
                    this.form = { ...this.form, ...Result };
                } else {
                    this.disabled = false
                }
                this.form.ProjectClassId = item.Id;
                this.dialogVisible = true;
            }
        },
    };
</script>
<style lang="scss" scoped>
    .job-container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: #f5f5f5;
    }

    .job-top {
        justify-content: space-between;
        margin-top: 20px;
    }

    .g-wrap {
        background: #fff;
    }

    .g-wrap.job-top {
        padding: 24px 24px 16px;
        border-bottom: 1px solid #eeeeee;
        // flex-direction: column;
    }

    .g-wrap.job-bottom {
        padding: 16px 24px;
        margin-bottom: 20px;
    }

    .btn-search {
        margin-left: 10px;
    }

    .job-bottom {
        display: flex;
        flex-direction: column;
        // margin-top: 20px;
        border-radius: 0px 0px 8px 8px;
        min-height: 30vh;
    }

    .job-item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;
        border: 1px solid #e2e4e8;
        border-radius: 6px;

        &:hover {
            box-shadow: 0px 2px 6px 0px #e7e7e7;
        }

        .item-top {
            padding: 10px;
            display: flex;
            justify-content: space-between;

            .item-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                .job-name {
                    font-size: 18px;
                    font-weight: 600;
                    color: #333333;
                }
            }

            .item-right {
                display: flex;
                align-items: center;

                .right-btn {
                    cursor: pointer;
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .item-bottom {
            display: flex;
            border-top: 1px solid #e7e8ea;
            background: #eeeeee;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            padding: 8px 10px;
            border-radius: 0 0 6px 6px;
        }

        .el-button {
            padding: 0px;
        }
    }

    .loading {
        text-align: center;
        height: 30px;
    }

    .image-slot {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f7fa;
    }

    .logo {
        width: 60px;
        height: 60px;
    }

    .dialogBox {
        width: 100%;
        height: 100%;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .nodata {
        flex: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-sizing: border-box;

        span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }
    }

    /deep/.el-rate__icon {
        //评分图标大小
        font-size: 28px!important;
    }
</style>