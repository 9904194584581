import request from "@/utils/request";
const controller = "TeacherEvaluate";

export function getInfo(id) {
  return request({
    url: `${controller}/GetInfo?id=${id}`,
    method: "get"
  });
}
export function createEntity(data) {
  return request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
}
